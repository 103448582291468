<template>
    <div>
        <div class="wrap">
            <div class="top-area">
                <div class="title">Notice</div>
            </div>
        </div>
        <div class="notice-detail-wrap">
            <div class="notice-subject-wrap">
                <div class="notice-subject">{{noticeInfo.notice_title}}</div>
                <div class="notice-date">{{noticeInfo.created_at}}</div>
            </div>
            <div class="notice-content-wrap">
                <div class="pc-notice-content ck-content" v-html="noticeInfo.notice_content"></div>
                <div class="mobile-notice-content ck-content" v-html="noticeInfo.notice_mobile_content"></div>
            </div>
        </div>
        <div class="notice-footer">
            <ul>
                <li>
                    <div class="prev-notice notice-footer-btn" v-if="noticeInfo.prev_notice_no" @click="moveNotcie(parseInt(noticeInfo.prev_notice_no))">이전글</div>
                </li>
                <li style="text-align: center;">
                    <div class="winex-btn primary-btn list-btn" @click="moveNoticeList" style="display: inline-block">목록 보기</div>
                </li>
                <li>
                    <div class="next-notice notice-footer-btn" v-if="noticeInfo.next_notice_no"  @click="moveNotcie(parseInt(noticeInfo.next_notice_no))">다음글</div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: "NoticeDetail",
        data() {
            return {
                noticeInfo: {

                }
            }
        },
        created() {
            let self = this
            this.$store.dispatch('notice/getNoticeView', { params: { no: self.$route.params.no }}).then((data) => {
                this.noticeInfo = data
            })
        },
        methods: {
            moveNotcie(no) {
                this.$router.push('/cs/notice/'+no)
            },
            moveNoticeList() {
                this.$router.push('/cs/notice')
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "@/assets/scss/notice-detail.scss";
</style>
<!-- 에디터 스타일 (에디터쪽 view 스타일을 적용하면 됩니다 ~ 나중에 CMS에서 어떤 에디터를 쓸 줄 모르는 상황) -->
<style lang="scss">
    .pc-notice-content {
        img {
            max-width: 100%;
        }
        p img {
            max-width: 100%;
        }
    }

    .mobile-notice-content {
        img {
            max-width: 100%;
        }
        p img {
            max-width: 100%;
        }
    }

</style>
